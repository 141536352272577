import React, {useEffect} from 'react'
import {connect} from 'react-redux';
import SmartBar from '../components/smart-bar.component';
import Menu from '../components/menu.component';
import Contacts from '../components/contact.component'
import BottomBarComponent from '../components/bottom-bar.component';

function AccueilPage(props) {

    useEffect(() => {
        async function loadPhotos() {
            const photoBdd = await fetch(`https://admin.maximeturpault.fr/profil/recupphoto`);
            const photo = await photoBdd.json();
            props.addPhoto(photo.photos);
        }

        loadPhotos().catch();

        async function loadPresentation() {
            const presetationBdd = await fetch(`https://admin.maximeturpault.fr/profil/recuppresentation`);
            const presentation = await presetationBdd.json();
            props.addPresentation(presentation.presentation);
        }

        loadPresentation().catch();

        async function loadProfil() {
            const profilBdd = await fetch(`https://admin.maximeturpault.fr/profil/recupprofil`);
            const profil = await profilBdd.json();
            props.addProfil(profil.profil);
        }

        loadProfil().catch();

        async function loadCategory() {
            const categoryBdd = await fetch(`https://admin.maximeturpault.fr/profil/recupcategorie`);
            const category = await categoryBdd.json();
            props.addCategory(category.categorie);
        }

        loadCategory().catch();
    }, []);

    const randomPicture = props.photos[Math.floor(Math.random(props.photos.length) * props.photos.length)]

    return (
        <>
            <SmartBar/>
            <Menu/>
            <div className="Accueil">
                {randomPicture === undefined ?
                    <>
                    </>
                    :
                    <img className="Accueil-Pictures" src={randomPicture.miniatures} alt={randomPicture.name}/>
                }
            </div>
            <Contacts/>
            <BottomBarComponent/>
        </>
    );
}

function mapStateToProps(state) {
    return {photos: state.photos}
}

function mapDispatchToProps(dispatch) {
    return {
        addPhoto: function (photos) {
            dispatch({
                type: 'addPhoto',
                photos
            })
        },
        addPresentation: function (presentation) {
            dispatch({
                type: 'addPresentation',
                presentation
            })
        },
        addProfil: function (profil) {
            dispatch({
                type: 'addProfil',
                profil
            })
        },
        addCategory: function (category) {
            dispatch({
                type: 'addCategory',
                category
            })
        }
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccueilPage);