import React, {useEffect, useState} from "react";
import {Provider} from 'react-redux';
import {combineReducers, createStore} from 'redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LoadingScreenPage from "./pages/loading-screen.page";
import Accueil from "./pages/accueil.page"
import Presentation from "./pages/presentation.page"
import Galerie from "./pages/galerie.page"
import Contact from "./pages/contact.page"
import photos from './reducers/photos.reducer';
import presentation from './reducers/presentation.reducer';
import profil from './reducers/profil.reducer';
import category from "./reducers/category.reducer";
import selection from "./reducers/selection.reducer";

const store = createStore(combineReducers({photos, presentation, profil, category, selection}));

function App() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 1500)
    }, [])

    return (

        <Provider store={store}>
            {!loading ? (
                <Router>
                    <Switch>
                        <Route exact path="/" component={Accueil}/>
                        <Route path="/presentation" component={Presentation}/>
                        <Route path="/galerie" component={Galerie}/>
                        <Route path="/contact" component={Contact}/>
                    </Switch>
                </Router>
            ) : (
                <LoadingScreenPage/>
            )}
        </Provider>
    );
}

export default App;


