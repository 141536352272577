import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

function SmartBarComponent(props) {

    const [showMenu, setShowMenu] = useState(false)

    return (
        <div className="Smart-Bar">
            <div className="Top-Menu">
                <h2 onClick={() => (setShowMenu(!showMenu))}><span className="Text-Menu">Menu</span></h2>
                {showMenu ?
                    <AiOutlineClose className='Margin-Menu-Close' onClick={() => (setShowMenu(!showMenu))}/>
                    :
                    <AiOutlineMenu className='Margin-Menu' onClick={() => (setShowMenu(!showMenu))}/>
                }
            </div>
            {showMenu ?
                <div className="Top-Smart">
                    <Link className="Item-Menu" to="/" onClick={() => (setShowMenu(false))}>Accueil</Link>
                    <Link className="Item-Menu" to="/presentation"
                          onClick={() => (setShowMenu(false))}>Présentation</Link>
                    {props.category.map((item, i) => (
                            <Link className="Item-Menu" key={i} to="/galerie" onClick={() => {
                                setShowMenu(false);
                                props.selectedCategory(item);
                            }}>{item}</Link>
                        )
                    )}
                    <Link className="Item-Menu" to="/contact" onClick={() => (setShowMenu(false))}>Contact</Link>

                </div>
                :
                <>
                </>
            }
            <div className="Mark-Smart">
                <Link className="Smart-Name" to="/"
                      onClick={() => (setShowMenu(false))}><span>Maxime Turpault</span></Link>
                <Link className="Smart-Name" to="/" onClick={() => (setShowMenu(false))}><img className='Logo'
                                                                                              src='/logomt.png'
                                                                                              alt='logo'/></Link>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {category: state.category}
}

function mapDispatchToProps(dispatch) {
    return {
        selectedCategory: function (selection) {
            dispatch({
                type: 'selectedCategory',
                selection
            })
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SmartBarComponent);