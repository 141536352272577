import React from 'react'
import {connect} from 'react-redux';

import SmartBar from '../components/smart-bar.component';
import Menu from '../components/menu.component';
import Contacts from '../components/contact.component'
import BottomBarComponent from '../components/bottom-bar.component';

function PresentationPage(props) {



    return (
        <>
            <SmartBar/>
            <Menu/>
            <div className='Presentation'>
                <div className='Presentation-Picture'>
                    <img className="Presentation-Pictures" src={props.presentation[0].photo} alt="Maxime Turpault"/>
                    <p className='Presentation-Parcours'>
                        {props.presentation[0].presentation}
                    </p>
                </div>
                <div className='Presentation-Desc'>
                    <p className='Presentation-Text'>
                        {props.presentation[0].description}
                    </p>
                </div>
            </div>
            <Contacts/>
            <BottomBarComponent/>
        </>
    );
}

function mapStateToProps(state) {
    return {presentation: state.presentation}
}

export default connect(
    mapStateToProps,
    null
)(PresentationPage);
