import React from 'react'

function LoadingScreenPage() {

    return (
        <div className='Loading'>
            <img className='Logo-Loading' src='/logomt.png' alt='logo'/>
        </div>
    );
}

export default LoadingScreenPage;