import React, {useState} from 'react'
import {connect} from 'react-redux';
import SmartBar from '../components/smart-bar.component';
import Menu from '../components/menu.component';
import Contacts from '../components/contact.component'
import BottomBarComponent from '../components/bottom-bar.component';

import {ImCross} from 'react-icons/im';

function GaleriePage(props) {

    const [bigPicture, setBigPicture] = useState('');
    const [name, setName] = useState('');

    const photos = props.photos.filter(item => item.categorie === props.selection).map((item, i) => (
            <img
                className="Pictures"
                key={i}
                src={item.miniatures}
                alt={item.name}
                onClick={() => {
                    setBigPicture(item.miniatures);
                    setName(item.name)
                }}/>
        )
    )

    return (
        <>
            {bigPicture !== '' ?
                <>
                    <div>
                        <ImCross className='BigPictures-Exit' onClick={() => setBigPicture('')}/>
                    </div>
                    <div className='BigPicture' onClick={() => setBigPicture('')}>
                        <img className='BigPictures' src={bigPicture} alt={name} onClick={() => setBigPicture('')}/>
                    </div>
                </>
                :
                <>
                </>
            }
            <SmartBar/>
            <Menu/>
            <div className='Pictures-Galerie'>
                {photos}
            </div>
            <Contacts/>
            <BottomBarComponent/>
        </>
    );
}

function mapStateToProps(state) {
    return {photos: state.photos, selection: state.selection}
}

export default connect(
    mapStateToProps,
    null
)(GaleriePage);
